import { PlayerPlateList } from "./PlayerPlateList";
import { useEffect, useState } from "react";
import { TopPageHeader } from "../header/TopPageHeader";
import { Footer } from "../footer/Footer";
import { charactersData } from "../data/CharactersData";
import { rankData } from "../data/rankData";
import { LoadingScreen } from "../common/LoadingScreen";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link } from "react-router-dom";

export const Home = () => {

  const [isLoading, setIsLoading] = useState(true)

  const { user, beingLoggedIn, setBeingLoggedIn } = useAuthContext();

  useEffect(() => {
    preloadImages();
  }, []);

  const preloadImages = () => {
    charactersData.forEach((character) => {
      const img = new Image();
      const url = "images/characters/" + character.characterName + ".png"
      img.src = url;
    })
    rankData.slice(1).forEach((rank) => {
      const img = new Image();
      const url = "images/ranks/" + rank.id + ".png"
      img.src = url;
    })
  };

  return (
    <>
      <div style={{
        width: "100%",
        maxWidth: "600px",
        margin: "0px auto",
        padding: "20px"
      }}>
        {(isLoading || beingLoggedIn) && <LoadingScreen />}
        <TopPageHeader user={user} beingLoggedIn={beingLoggedIn} setBeingLoggedIn={setBeingLoggedIn} />
        <p style={{ display: "block", fontSize: "24px", fontWeight: "bold" }}>トップページ</p>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>SMASH CONNECTとは</p>
        <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
        <p style={{ display: "block", fontSize: "12px", margin: "12px", }}>SMASH CONNECT（スマッシュコネクト）は、スマブラプレイヤー同士を繋げるウェブサイトです。<br />

          スマブラプレイヤーたちは対戦したいファイターをX上に投稿し、その使い手を募るポストが散見されます。ですが、対戦したい使い手の元にその投稿が届く保証はなく、ましてやレベルが近しい対戦相手が見つかる可能性は決して高くありません。

          現状のスマブラ界隈には、「誰がどのファイターをどの程度扱えるか」の情報が集約されておらす、能動的に対戦相手を見つける仕組みが確立されていません。<br />

          本サイトでは、「誰がどのファイターをどの程度扱えるか」の情報を検索可能なものとして一元的に管理し、希望の対戦相手を見つけることの手助けを目的としています。自身が扱えるファイターを本サイトに登録することができます。そして、ファイターや強さを元にプレイヤーを検索し、希望の対戦相手を見つけ出すことができます。<br />

          まずはXでログインし、自分の情報を登録しましょう！</p>

        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold", marginTop: "20px" }}>おまかせで遊ぶ</p>
        <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
        <p style={{ display: "block", fontSize: "12px", margin: "12px", }}>
          スマブラSPに搭載されている機能の「おまかせ」では、自分の得意ファイターだけに絞ったおまかせや一度選出されたファイターを弾いて遊ぶ等の設定は備わっていません。
          <br />
          本サイトの機能を用いることで、あなたのおまかせランクを登録した上で、そのランクを元に、上位ファイターから無作為にファイターをピックしたり、一度使用したファイターが選出されないようにしたり、柔軟におまかせで遊ぶことができます。好きなファイターのみのおまかせで戦いたい、実力差のある人同士でおまかせを楽しみたい場面で是非ご活用ください。
        </p>

        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center"
        }}>
          <Link className="blackButton" to="/omakases" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "120px",
            color: "white",
            background: "black",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "10px",
            textDecoration: "none",
            margin: "10px",
            marginTop: "5px"
          }}>おまかせで遊ぶ</Link>
        </div>

        <PlayerPlateList setIsLoading={setIsLoading} />
        <Footer></Footer>
      </div>
    </>
  )
}
