import { useEffect, useState } from "react";
import { CharacterImg } from "./CharacterImg";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { IconButton } from "@mui/material";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { CopyImgButton } from "../../playerpage/CopyImgButton";

export const OmakaseCrewBattle = ({ crewCharacters, setCrewCharacters, stockCount, setSettingDone, setBattleStarted }) => {
  const handleOnClick = () => {
    setBattleStarted(false);
    setSettingDone(false);
    localStorage.setItem('omakaseCrew', JSON.stringify([[], false, false]));
  }
  return (
    <div style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: "20px"
    }}>
      <h2 style={{
        color: '#2c3e50',
        fontSize: '20px',
        fontWeight: '600',
      }}>
        バトルスタート
      </h2>

      <div id='omakase-crew'
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "0px",
        }}>
        {crewCharacters.map((character, index) => {
          return (
            <CharacterStockItem
              key={index}
              index={index}
              character={character}
              initialStock={stockCount}
              crewCharacters={crewCharacters}
              setCrewCharacters={setCrewCharacters}
            />
          )
        })}
      </div>

      <div style={{ display: "flex", justifyContent: "end", width: "90%" }}>
        <CopyImgButton type={"omakase-crew"} />
      </div>

      <button style={{ ...buttonStyle, width: "160px", display: "flex", margin: "20px auto", justifyContent: "center" }} onClick={handleOnClick}>クルーを再作成</button>
    </div>
  )
}

const buttonStyle = {
  padding: "10px 10px",
  borderRadius: "8px",
  border: "none",
  background: "linear-gradient(135deg, #424242, #000000)",
  color: "#fff",
  fontWeight: "bold",
  cursor: "pointer",
  boxShadow: "0 3px 6px rgba(0,0,0,0.3)",
  transition: "background 0.3s ease",
};

const CharacterStockItem = ({ index, character, initialStock, crewCharacters, setCrewCharacters }) => {
  const handleStockReduce = () => {
    setCrewCharacters((prev) => {
      const newCrewCharacters = [...prev];
      const character = newCrewCharacters[index];
      newCrewCharacters[index] = { ...character, stockCount: Math.max(0, character.stockCount - 1) };
      localStorage.setItem('omakaseCrew', JSON.stringify([newCrewCharacters, true, true]));
      return newCrewCharacters;
    });

  };

  const handleStockIncrease = () => {
    setCrewCharacters((prev) => {
      const newCrewCharacters = [...prev];
      const character = newCrewCharacters[index];
      newCrewCharacters[index] = { ...character, stockCount: Math.min(initialStock, character.stockCount + 1) };
      localStorage.setItem('omakaseCrew', JSON.stringify([newCrewCharacters, true, true]));
      return newCrewCharacters;
    });

  };

  const stockIndicatorStyle = (active) => ({
    width: active ? "28px" : "16px",
    height: active ? "28px" : "16px",
    background: active
      ? "linear-gradient(145deg, #ff4d4d, #ff0000)"  // アクティブ時は赤系のグラデーション
      : "#d1d5db",
    opacity: active ? 1 : 0.5,
    borderRadius: "50%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transform: active ? "scale(1.05)" : "scale(1)",
    transition: "all 0.3s ease",
  });

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "90%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "16px auto 16px",
        gap: "20px",
        padding: "30px",
        background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
        borderRadius: "12px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
      }}>
      <div
        style={{
          position: "absolute",
          top: "8px",
          left: "8px",
          color: "black",
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        No. {index + 1}
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        textAlign: "center"
      }}>
        <div >
          <CharacterImg character={character} characterName={character.characterName} />
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}>

          <IconButton
            sx={{ color: 'black' }}
            onClick={handleStockReduce}
            disabled={crewCharacters[index].stockCount === 0}>
            <IndeterminateCheckBoxIcon fontSize="large" />
          </IconButton>

          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}>
            {Array.from({ length: initialStock }).map((_, idx) => (
              <div
                key={idx}
                style={{
                  width: "28px",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={stockIndicatorStyle(idx < crewCharacters[index].stockCount)} />
              </div>
            ))}
          </div>
          <IconButton
            sx={{ color: 'black' }}
            onClick={handleStockIncrease}
            disabled={crewCharacters[index].stockCount === initialStock}>
            <AddBoxIcon fontSize="large" />
          </IconButton>

        </div>
        <span style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "0px" }}>
          残ストック
          <span style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px" }}>
            {crewCharacters[index].stockCount} / {initialStock}
          </span>
        </span>
      </div>
    </div>
  );
};