import React, { useEffect, useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { CharacterImg } from './CharacterImg';
import { useModal } from '../../../hooks/useModal';
import { CharacterSelectModal } from './characterSelectModal';
import { IconButton } from '@mui/material';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';


export const OmakaseCrew = ({ charCount, stockCount, topCharCount, crewCharacters, setCrewCharacters, setBattleStarted, omakaseTierLists }) => {

  const [characterList, setCharacterList] = useState([])

  const handleOnClick = () => {
    // 既に選択されているキャラ（nullでないもの）を抽出
    const alreadySelected = crewCharacters.filter(character => character !== null);

    // omakaseTierLists の上位 topCharCount から、既に選ばれているキャラを除外
    const availablePool = characterList
      .slice(0, topCharCount)
      .filter(character => !alreadySelected.some(c => c.id === character.id));


    // crewCharacters の null を availablePool から無作為に選んだキャラで置き換える
    const newCrewCharacters = crewCharacters.map(character => {
      if (character === null && availablePool.length > 0) {
        const randomIndex = Math.floor(Math.random() * availablePool.length);
        const selected = availablePool[randomIndex];
        // 選ばれたキャラは重複しないように配列から削除
        availablePool.splice(randomIndex, 1);
        return { ...selected, stockCount: stockCount };
      }
      return { ...character, stockCount: stockCount };
    });

    // 更新した crewCharacters をセットし、バトル開始状態にする
    setCrewCharacters(newCrewCharacters);
    setBattleStarted(true);
    localStorage.setItem("omakaseCrew", JSON.stringify([newCrewCharacters, true, true]));
  }

  useEffect(() => {
    setCrewCharacters(Array(charCount).fill(null));
    const sortedCharacterList = sortCharacterList(omakaseTierLists)
    setCharacterList(sortedCharacterList)
  }, [charCount, setCrewCharacters, omakaseTierLists]);

  return (
    <div style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "0px",
      padding: "20px"
    }}>
      <h2 style={{
        color: '#2c3e50',
        // marginBottom: '20px',
        fontSize: '20px',
        fontWeight: '600',
      }}>
        ファイターを選択してください
      </h2>
      {crewCharacters.map((character, index) => {
        return (
          <CharacterStockItem
            key={index}
            index={index}
            character={character}
            initialStock={stockCount}
            setCrewCharacters={setCrewCharacters}
          />
        );
      }
      )}
      <button style={{ ...buttonStyle, width: "160px", display: "flex", margin: "20px auto", justifyContent: "center" }} onClick={handleOnClick}>ファイター決定</button>
    </div>
  )
}

const sortCharacterList = (tls) => {
  let sortedCharacterList = []
  tls.forEach((tl) => {
    tl.characters.forEach((character) => {
      sortedCharacterList.push(character)
    })
  })
  return sortedCharacterList
}

const CharacterStockItem = ({ index, character, initialStock, setCrewCharacters }) => {
  const setSelectedCharacter = (selectedCharacter) => {
    setCrewCharacters(prev => {
      const newCrewCharacters = [...prev];
      newCrewCharacters[index] = selectedCharacter;
      return newCrewCharacters;
    });
  }

  const { isOpen, onClose, onOpen } = useModal();

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "90%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "16px auto",
        gap: "20px",
        padding: "30px",
        background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
        borderRadius: "12px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
      }}>
      <div
        style={{
          position: "absolute",
          top: "8px",
          left: "8px",
          color: "black",
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        No. {index + 1}
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        textAlign: "center"
      }}>
        <div>
          {character !== null ?
            <button onClick={() => onOpen('char')} style={{ padding: 0, border: "none", background: "none" }}>
              <CharacterImg characterName={character.characterName}></CharacterImg>
            </button>
            :
            <button onClick={() => onOpen('char')} style={{ padding: 0, border: "none", background: "none" }}>
              <CharacterImg characterName={'おまかせキャラ'}></CharacterImg>
            </button>
          }

          {isOpen('char') && <CharacterSelectModal index={index} onClose={onClose} setSelectedCharacter={setSelectedCharacter} />}
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}>

          <IconButton
            sx={{ color: 'black' }}
            disabled={true}>
            <IndeterminateCheckBoxIcon fontSize="large" />
          </IconButton>

          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}>
            {Array.from({ length: initialStock }).map((_, idx) => (
              <div
                key={idx}
                style={{
                  width: "28px",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={stockIndicatorStyle(true)} />
              </div>
            ))}
          </div>
          <IconButton
            sx={{ color: 'black' }}
            disabled={true}>
            <AddBoxIcon fontSize="large" />
          </IconButton>

        </div>
        <span style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "0px" }}>
          残ストック
          <span style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px" }}>
            {initialStock} / {initialStock}
          </span>
        </span>
      </div>
    </div>
  );
};

const stockIndicatorStyle = (active) => ({
  width: active ? "28px" : "16px",
  height: active ? "28px" : "16px",
  background: active
    ? "linear-gradient(145deg, #ff4d4d, #ff0000)"  // アクティブ時は赤系のグラデーション
    : "#d1d5db",
  opacity: active ? 1 : 0.5,
  borderRadius: "50%",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transform: active ? "scale(1.05)" : "scale(1)",
  transition: "all 0.3s ease",
});

const buttonStyle = {
  padding: "10px 10px",
  borderRadius: "8px",
  border: "none",
  background: "linear-gradient(135deg, #424242, #000000)",
  color: "#fff",
  fontWeight: "bold",
  cursor: "pointer",
  boxShadow: "0 3px 6px rgba(0,0,0,0.3)",
  transition: "background 0.3s ease",
};