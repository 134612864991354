import { useEffect, useState } from "react";
import { LoadingScreen } from "../common/LoadingScreen"
import { TopPageHeader } from "../header/TopPageHeader"
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link } from "react-router-dom";
import { Footer } from "../footer/Footer";
import { ScreenshotButton } from "../playerpage/ScreenshotButton";
import { PlayerOmakaseTierTable } from "../playerpage/componnts/omakaseTierTable/PlayerOmakaseTierTable";
import { axiosInstance } from "../../configs/axios";

export const OmakaseTopPage = () => {

  const [isLoading, setIsLoading] = useState(true)

  const [omakaseTierLists, setOmakaseTierLists] = useState([])

  const { user, beingLoggedIn, setBeingLoggedIn } = useAuthContext();

  useEffect(() => {
    window.scrollTo({
      top: 0
    });

    if (user == null && !beingLoggedIn) {
      // １.5秒待たないとuserが取得できてもLoadingを閉じてしまう
      setTimeout(() => setIsLoading(false), 1500)
      setOmakaseTierLists([])
      return
    }
    setIsLoading(true)
    Promise.all([
      axiosInstance.get(`/userinfo?userid=${user?.id}`)
    ]).then(([userInfoResponse]) => {
      const userInfo = userInfoResponse.data
      const newTierLists = JSON.parse(userInfo.omakaseTier).filter((tierList) => { return tierList.characters.length !== 0 && tierList.id !== '0' })

      setOmakaseTierLists(newTierLists)

      // 1秒待たないとSliderの初期値が反映しきれない（アニメーションが入る）
      setTimeout(() => setIsLoading(false), 1000)
    })
  }, [user])

  return (
    <div style={{
      width: "100%",
      maxWidth: "600px",
      margin: "0px auto",
      padding: "20px"
    }}>
      {(isLoading || beingLoggedIn) && <LoadingScreen />}
      <TopPageHeader user={user} beingLoggedIn={beingLoggedIn} setBeingLoggedIn={setBeingLoggedIn} />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }} state={{ test: "aa" }}>
            トップページ
          </Link>
          {user &&
            <p style={{ display: "block", fontSize: "14px", fontWeight: "bold", margin: "auto 5px" }}>
              ＞
            </p>
          }
          {user &&
            <Link className="link" to={`/playerpage?userid=${user?.id}`} style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>
              マイページ
            </Link>
          }
        </div>

      </div>

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせで遊ぶ</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <h1 style={{
        textAlign: 'center',
        fontSize: '1.2em',
        borderBottom: '2px solid #ddd',
        paddingBottom: '10px',
        marginTop: '20px'
      }}>
        おまかせクルー
      </h1>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>おまかせクルーとは</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        おまかせクルーとは、ストック引き継ぎ制対戦（クルーバトル）で楽しむためのサポートツールです。また、より戦略性の高いおまかせ対戦を楽しむのに活用いただけます。
        おすすめの遊び方を紹介していますが、特に細かなルール等の制約等は設けていませんので、ご自由に遊んでみてください。
      </p>

      <Link style={{ ...buttonStyle, display: "flex", justifyContent: "center", width: "200px", fontSize: "14px", fontWeight: "bold", textDecoration: "none", margin: "10px auto" }} to={'/omakasecrew'}>おまかせクルーで遊ぶ</Link>

      <h1 style={{
        textAlign: 'center',
        fontSize: '1.2em',
        borderBottom: '2px solid #ddd',
        paddingBottom: '10px',
        marginTop: '30px'
      }}>
        条件付きおまかせ
      </h1>

      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>条件付きおまかせとは</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        スマブラSPの「おまかせ」では、得意なファイターに限定して無作為選択するような機能は搭載されていません。<br />
        本サイトの「条件付きおまかせ」を用いることで、登録したあなたのおまかせランクを元に、上位ファイターから無作為にファイターをピックすることができます。好きなファイターのみのおまかせで戦いたい、実力差のある人同士でおまかせを楽しみたい場面でご活用ください。
      </p>
      <Link style={{ ...buttonStyle, display: "flex", justifyContent: "center", width: "200px", fontSize: "14px", fontWeight: "bold", textDecoration: "none", margin: "10px auto", marginBottom: "30px" }} to={'/omakase'}>条件付きおまかせで遊ぶ</Link>

      {user &&
        <div>
          <div>
            <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
            <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
          </div>


          <PlayerOmakaseTierTable tierLists={omakaseTierLists} setTierLists={setOmakaseTierLists} isLoggedInUser={true} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ScreenshotButton type={"omakase-tier-table"} />
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center"
          }}>
            <Link className="blackButton" to="/mypage" style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "20px"
            }}>マイページを編集</Link>
          </div>
        </div>}

      <Footer />

    </div>
  )
}

const buttonStyle = {
  padding: "10px 10px",
  borderRadius: "8px",
  border: "none",
  background: "linear-gradient(135deg, #424242, #000000)",
  color: "#fff",
  fontWeight: "bold",
  cursor: "pointer",
  boxShadow: "0 3px 6px rgba(0,0,0,0.3)",
  transition: "background 0.3s ease",
};
