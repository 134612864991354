import { Slider } from "@mui/material"
import { useCallback, useEffect, useState } from "react";

export const OmakaseCrewSetting = ({ user, charCount, stockCount, topCharCount, setCharCount, setStockCount, setTopCharCount, setSettingDone, omakaseTierLists }) => {
  const [characterLength, setCharacterLength] = useState(0);

  const handleOnChangeCharCount = (event, newValue) => {
    setCharCount(newValue);
  }

  const handleOnChangeStockCount = (event, newValue) => {
    setStockCount(newValue);
  }

  const handleOnChangeTopCharCount = (event, newValue) => {
    setTopCharCount(newValue);
  }


  const charMarks = [
    { value: 3, label: '3' },
    { value: 5, label: '5' },
    { value: 7, label: '7' }
  ];

  const stockMarks = [
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' }
  ];

  const [topCharMarks, setTopCharMarks] = useState([
    { value: 10, label: '10体' },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 70 },
    { value: 80 },
    { value: 86 },
  ])

  const createMarks = useCallback((characterLength) => {
    const newMarks = [...topCharMarks, { value: characterLength }].filter((mark) => {
      return mark.value <= characterLength
    })
    newMarks[newMarks.length - 1].label = `${characterLength}体`
    return newMarks
  }, []);

  useEffect(() => {
    let length = 0;
    omakaseTierLists.forEach((tierList) => {
      length += tierList.characters.length;
    });
    setCharacterLength(length);
    setTopCharCount(length)
    setTopCharMarks(createMarks(length));
  }
    , [charCount, createMarks, omakaseTierLists]);

  return (
    <div style={{
      padding: "20px",
      textAlign: "center",
    }}>
      <h2
        style={{
          color: "#2c3e50",
          marginBottom: "20px",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        クルーを設定してください
      </h2>
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          background: "linear-gradient(135deg, #ffffff, #e6e6e6)",
          borderRadius: "12px",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
          maxWidth: "500px",
          margin: "30px auto",
          fontFamily: "'Roboto', sans-serif",
          letterSpacing: "1px",
        }}
      >
        <div style={{ marginBottom: "25px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <div style={{ fontWeight: "600", textAlign: "left" }}>
              ファイター数
            </div>
            <div style={{ fontWeight: "600", textAlign: "left" }}>
              <span style={{ fontSize: "1.75em", fontWeight: "bold" }}>
                {charCount}
              </span>
              体
            </div>
          </div>

          <Slider
            min={3}
            max={7}
            defaultValue={3}
            marks={charMarks}
            onChange={handleOnChangeCharCount}
            size="lg"
            style={{
              margin: "10px 20px",
              width: "90%",
            }}
            sx={sliderStyle}
          />
        </div>

        <div style={{ marginBottom: "25px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <div style={{ fontWeight: "600", textAlign: "left" }}>
              ストック数
            </div>
            <div style={{ fontWeight: "600", textAlign: "left" }}>
              <span style={{ fontSize: "1.75em", fontWeight: "bold" }}>
                {stockCount}
              </span>
              ストック
            </div>
          </div>

          <Slider
            min={3}
            max={6}
            defaultValue={3}
            marks={stockMarks}
            step={null}
            onChange={handleOnChangeStockCount}
            size="lg"
            style={{
              margin: "10px 20px",
              width: "90%",
            }}
            sx={sliderStyle}
          />
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <div style={{ fontWeight: "600", textAlign: "left" }}>おまかせ</div>
            <div style={{ fontWeight: "600", textAlign: "left" }}>
              上位
              <span style={{ fontSize: "1.75em", fontWeight: "bold" }}>
                {topCharCount}
              </span>
              体選出
            </div>
          </div>

          <Slider
            key={`slider-${characterLength === 0}`}
            min={10}
            max={characterLength}
            step={null}
            defaultValue={characterLength}
            marks={topCharMarks}
            onChange={handleOnChangeTopCharCount}
            size="lg"
            style={{
              margin: "10px 20px 20px",
              width: "90%",
            }}
            sx={sliderStyle}
          />
        </div>


      </div>
      <button
        onClick={() => setSettingDone(true)}
        disabled={!user}
        style={{
          ...buttonStyle,
          width: "160px",
          margin: "30px 0",
          padding: "10px 20px",
          border: "none",
          borderRadius: "8px",
          color: "#fff",
          fontSize: "16px",
          fontWeight: "bold",
          cursor: "pointer",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          transition: "background 0.3s ease",
          ...(!user ? disabledStyle : {}),
        }}
      >
        設定完了
      </button>
    </div>
  );

};

const disabledStyle = {
  opacity: 0.6,
  cursor: "not-allowed",
  boxShadow: "none",
};

const sliderStyle = {
  "& .MuiSlider-track": {
    backgroundColor: "black", // バーの色を黒に
    color: "black",
    opacity: 1,

  },
  "& .MuiSlider-rail": {
    backgroundColor: "black",
    color: "black",
    opacity: 1,
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "red", // サムの色を赤に
    "&:hover": {
      boxShadow: "0 0 0 3px rgba(255, 0, 0, 0.5)", // ホバー時の影の色を赤に
    },
    "&.Mui-focusVisible, &.Mui-active": {
      boxShadow: "0 0 0 5px rgba(255, 0, 0, 0.3)", // ホバー時の影の色を赤に
    },
  },
  "& .MuiSlider-mark": {
    backgroundColor: "black", // マークの色を黒に
    height: "8px", // マークの高さを大きめに
    width: "8px", // マークの幅を大きめに
    borderRadius: "50%", // 丸くする
    border: "2px solid black",
    opacity: 1
  },
  "& .MuiSlider-markLabel": {
    fontSize: "16px",
  },
}

const buttonStyle = {
  padding: "10px 10px",
  borderRadius: "8px",
  border: "none",
  background: "linear-gradient(135deg, #424242, #000000)",
  color: "#fff",
  fontWeight: "bold",
  cursor: "pointer",
  boxShadow: "0 3px 6px rgba(0,0,0,0.3)",
  transition: "background 0.3s ease",
};