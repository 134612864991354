import html2canvas from "html2canvas";

export const ScreenshotButton = ({ type }) => {

  let screenshotTitle = ""

  // ファイル名
  switch (type) {
    case "tier-table":
      screenshotTitle = "ファイターランク";
      break;
    case "omakase-tier-table":
      screenshotTitle = "おまかせランク";
      break;
    case "omakase-five-result":
      screenshotTitle = "おま５ピック結果";
      break;
    case "omakase-result":
      screenshotTitle = "おまかせピック結果";
      break;
    case "omakase-crew":
      screenshotTitle = "おまかせクルー";
      break;
    default:
      screenshotTitle = "対戦したいファイター";
      break;
  }

  // html2canvas で得られる URI を用いてダウンロードさせる関数
  // Ref: https://stackoverflow.com/questions/31656689/how-to-save-img-to-users-local-computer-using-html2canvas
  const saveAsImage = uri => {
    const downloadLink = document.createElement("a");
    if (typeof downloadLink.download === "string") {
      downloadLink.href = uri;
      // ファイル名
      downloadLink.download = screenshotTitle + "_" + getFormattedDate()
      // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
      document.body.appendChild(downloadLink);
      // ダウンロードリンクが設定された a タグをクリック
      downloadLink.click();
      // Firefox 対策で追加したリンクを削除しておく
      document.body.removeChild(downloadLink);
    } else {
      window.open(uri);
    }
  }

  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
  };

  const onClickExport = () => {
    const target = document.getElementById(type);
    const clone = target.cloneNode(true);

    const parent = document.createElement('div');

    const img = document.createElement('img');
    img.src = "/images/ロゴ.png";
    img.alt = "ロゴ";
    img.style.width = "200px";
    img.style.objectFit = "contain";
    img.style.margin = "auto 10px"

    parent.appendChild(img)

    // <p>要素を作成
    const title = document.createElement('p');
    title.textContent = screenshotTitle;
    title.style.display = "block";
    title.style.fontSize = "18px";
    title.style.fontWeight = "bold";

    // <hr>要素を作成
    const separator = document.createElement('hr');
    separator.style.border = "none";
    separator.style.height = "2px";
    separator.style.background = "black";
    separator.style.margin = "0px";

    // parentに<p>と<hr>を追加
    parent.appendChild(title);
    parent.appendChild(separator);

    parent.appendChild(clone)
    parent.style.padding = '20px'
    parent.style.width = `600px`;
    parent.style.position = 'absolute';
    parent.style.top = '-9999px'; // 画面外に配置

    document.body.appendChild(parent);

    html2canvas(parent, {
      useCORS: true, // 外部リソースのクロスオリジン問題を回避するため
    }).then(canvas => {
      // クローン要素を削除
      document.body.removeChild(parent);
      const targetImgUri = canvas.toDataURL("image/png");
      saveAsImage(targetImgUri);
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", fontWeight: "bold", fontSize: "12px" }}>
      <p>
        ダウンロード
      </p>
      <button className="blackButton" onClick={onClickExport} style={{ background: "white", border: "none", margin: "5px", cursor: "pointer", WebkitTouchCallout: 'none' }}>
        <img src="/images/ダウンロード.png" alt="ダウンロード" style={{ width: "18px", objectFit: "cover" }} />
      </button>
    </div>
  )
}
