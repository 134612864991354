import { useEffect, useState } from "react";
import { TopPageHeader } from "../../header/TopPageHeader";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Alert, AlertTitle } from "@mui/material";
import { OmakaseCrewSetting } from "./OmakaseCrewSetting";
import { OmakaseCrew } from "./OmakaseCrew";
import { OmakaseCrewBattle } from "./OmakaseCrewBattle";
import { axiosInstance } from "../../../configs/axios";
import { Footer } from "../../footer/Footer";
import { PlayerOmakaseTierTable } from "../../playerpage/componnts/omakaseTierTable/PlayerOmakaseTierTable";
import { ScreenshotButton } from "../../playerpage/ScreenshotButton";
import { OmakaseCrewTutorial } from "./OmakaseCrewTutorial";
import { LoadingScreen } from "../../common/LoadingScreen";

export const OmakaseCrewPage = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [charCount, setCharCount] = useState(3)

  const [stockCount, setStockCount] = useState(3)

  const [topCharCount, setTopCharCount] = useState(10)

  const [characterList, setCharacterList] = useState([])

  const [settingDone, setSettingDone] = useState(false);

  const [crewCharacters, setCrewCharacters] = useState([]);

  const [battleStarted, setBattleStarted] = useState(false);

  const { user, beingLoggedIn, setBeingLoggedIn } = useAuthContext();

  const [omakaseTierLists, setOmakaseTierLists] = useState([])

  useEffect(() => {
    window.scrollTo({
      top: 0
    });

    const storageOmakaseCrew = JSON.parse(localStorage.getItem("omakaseCrew"))

    if (storageOmakaseCrew) {
      const [storageCrewCharacters, settingDone, battleStarted] = storageOmakaseCrew
      setCrewCharacters(storageCrewCharacters)
      setSettingDone(settingDone)
      setBattleStarted(battleStarted)
    }

    if (user == null && !beingLoggedIn) {
      // １.5秒待たないとuserが取得できてもLoadingを閉じてしまう
      setTimeout(() => setIsLoading(false), 1500)
      setOmakaseTierLists([])
      return
    }
    setIsLoading(true)
    Promise.all([
      axiosInstance.get(`/userinfo?userid=${user?.id}`)
    ]).then(([userInfoResponse]) => {
      const userInfo = userInfoResponse.data
      const newTierLists = JSON.parse(userInfo.omakaseTier).filter((tierList) => { return tierList.characters.length !== 0 && tierList.id !== '0' })
      const sortedCharacterList = sortCharacterList(newTierLists)
      setCharacterList(sortedCharacterList)
      setOmakaseTierLists(newTierLists)

      // 1秒待たないとSliderの初期値が反映しきれない（アニメーションが入る）
      setTimeout(() => setIsLoading(false), 1000)
    })
  }, [user])

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [settingDone, battleStarted])

  const sortCharacterList = (tls) => {
    let sortedCharacterList = []
    tls.forEach((tl) => {
      tl.characters.forEach((character) => {
        sortedCharacterList.push(character)
      })
    })
    return sortedCharacterList
  }

  return (
    <div style={{
      width: "100%",
      maxWidth: "600px",
      margin: "0px auto",
      padding: "20px"
    }}>
      {(isLoading || beingLoggedIn) && <LoadingScreen />}
      <TopPageHeader user={user} beingLoggedIn={beingLoggedIn} setBeingLoggedIn={setBeingLoggedIn} />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }} state={{ test: "aa" }}>
            トップページ
          </Link>
          <p style={{ display: "block", fontSize: "14px", fontWeight: "bold", margin: "auto 5px" }}>
            ＞
          </p>


          <Link className="link" to={'/omakases'} style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>
            おまかせで遊ぶ
          </Link>

        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせクルー</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      {!user ?
        <Alert sx={{ fontSize: "12px", borderRadius: "10px", margin: "10px" }} severity="error">
          <AlertTitle sx={{ fontSize: "12px", fontWeight: "bold" }}>ログインが必要です
          </AlertTitle>
          おまかせクルーで遊ぶためにはログインが必要です。
        </Alert>
        : characterList.length <= 9 &&
        <Alert sx={{ fontSize: "12px", borderRadius: "10px", margin: "10px" }} severity="error">
          <AlertTitle sx={{ fontSize: "12px", fontWeight: "bold" }}>おまかせランクに登録されているファイターが足りません
          </AlertTitle>
          おまかせクルーで遊ぶためにはおまかせランクに10体以上登録されている必要があります。
        </Alert>
      }

      {
        (!settingDone || ! user ) ? (
          <OmakaseCrewSetting
            user={user}
            charCount={charCount}
            setCharCount={setCharCount}
            stockCount={stockCount}
            setStockCount={setStockCount}
            topCharCount={topCharCount}
            setTopCharCount={setTopCharCount}
            setSettingDone={setSettingDone}
            omakaseTierLists={omakaseTierLists}
          />
        ) : !battleStarted ? (
          <OmakaseCrew
            charCount={charCount}
            stockCount={stockCount}
            topCharCount={topCharCount}
            crewCharacters={crewCharacters}
            setCrewCharacters={setCrewCharacters}
            setBattleStarted={setBattleStarted}
            omakaseTierLists={omakaseTierLists}
          />
        ) : (
          <OmakaseCrewBattle
            crewCharacters={crewCharacters}
            setCrewCharacters={setCrewCharacters}
            stockCount={stockCount}
            topCharCount={topCharCount}
            setSettingDone={setSettingDone}
            setBattleStarted={setBattleStarted}
          />
        )
      }
      <OmakaseCrewTutorial />

      {user &&
        <div>
          <div>
            <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
            <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
          </div>


          <PlayerOmakaseTierTable tierLists={omakaseTierLists} setTierLists={setOmakaseTierLists} isLoggedInUser={true} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ScreenshotButton type={"omakase-tier-table"} />
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center"
          }}>
            <Link className="blackButton" to="/mypage" style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "20px"
            }}>マイページを編集</Link>
          </div>
        </div>}

      <Footer />

    </div>
  )
}

