export const OmakaseTutorial = () => {
  return (
    <div>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>条件付きおまかせとは</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        スマブラSPの「おまかせ」では、得意なファイターに限定して無作為選択するような機能は搭載されていません。<br />
        本サイトの「条件付きおまかせ」を用いることで、登録したあなたのおまかせランクを元に、上位ファイターから無作為にファイターをピックすることができます。好きなファイターのみのおまかせで戦いたい、実力差のある人同士でおまかせを楽しみたい場面でご活用ください。
      </p>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>使い方</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        はじめにマイページのおまかせランクを編集して、自分のおまかせランクを作成しましょう。左右差を考慮して上位ランクからピックされますので、意識して配置してください。 <br />
        次に上のスライダーから何体のファイターからピックするかを設定してください。「おまかせ」ボタンを押せばファイターが表示されるので、試合を開始しましょう。<br />
        おまかせの信頼性担保のため、本ページにアクセス時や再抽選時は30秒間押せない仕様にしています。
      </p>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>おすすめの遊び方</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        <strong>1.</strong>  スライダーの数値を40等、適当な数値で対戦を始めてください。<br />
        <strong>2.</strong>  勝ったら「＋」ボタンでスライダーの数値を増やし、負けたら「ー」ボタンで減らします。勝ったらおまかせの条件を厳しく、負けると緩くします。<br />
        <strong>3.</strong>  スライダーの数値が一番右の状態、すなわち全キャラのおまかせで勝つと勝利です。<br /><br />
        決着に時間がかなりかかる想定ですので、スライダーの初期位置・勝った時負けた時の「±」ボタンの押す回数は各自調整して遊んでみてください。
      </p>
    </div>
  )
}