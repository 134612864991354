export const OmakaseCrewTutorial = () => {
  return (
    <div style={{ marginBottom: "30px" }}>
      <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>おまかせクルーとは</p>
      <p style={{ display: "block", fontSize: "14px", margin: "12px" }}>
        おまかせクルーとは、ストック引き継ぎ制対戦（クルーバトル）で楽しむためのサポートツールです。また、より戦略性の高いおまかせ対戦を楽しむのに活用いただけます。
        おすすめの遊び方を紹介していますが、特に細かなルール等の制約等は設けていませんので、ご自由に遊んでみてください。
      </p>

      <h1 style={{
        textAlign: 'center',
        fontSize: '1.5em',
        borderBottom: '2px solid #ddd',
        paddingBottom: '10px'
      }}>
        基本的な使い方
      </h1>
      <div style={{ display: "block", fontSize: "14px", margin: "12px" }}>
        <p style={{ marginBottom: "12px" }}>
          対戦に参加する方それぞれが、以下ステップでクルーを作成しましょう。
        </p>
        <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: "1.2em" }}>
          1. クルーを設定
        </p>
        <p style={{ marginBottom: "12px" }}>
          設定項目は以下の3つです。
        </p>
        <ul style={{ paddingLeft: "20px", marginBottom: "12px" }}>
          <li>
            <strong>ファイター数</strong>：クルーのファイター数を設定できます。
          </li>
          <li style={{ marginTop: "8px" }}>
            <strong>ストック数</strong>：各ファイターのストック数を設定できます。<br />
            4 ストック以上に設定して、3 ストック対戦に活用することもできます。<br />
            <p style={{ margin: "5px 0 0px 0", fontWeight: "bold" }}>5 ストックに設定した例：</p>
            <p style={{ marginLeft: "20px" }}>
              3 ストック対戦で<br />
              <ul style={{ paddingLeft: "20px", margin: 0 }}>
                <li>1 ストック残しで勝利 → 2 ストック消費 → 5 - 2 = 3 ストックに減らす</li>
                <li>敗北 → 3 ストック消費 → 5 - 3 = 2 ストックに減らす</li>
              </ul>
            </p>

          </li>
          <li style={{ marginTop: "8px" }}>
            <strong>おまかせ選択時の条件</strong>：おまかせを選択した際に、あなたのおまかせランクを元に上位何体から選出するかを設定できます。
          </li>
        </ul>
        <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: "1.2em" }}>
          2. ファイターを選択
        </p>
        <p style={{ marginBottom: "12px" }}>
          クルーのファイターを選択しましょう。選択しなかった場合は、おまかせで選択されます。
        </p>
        <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: "1.2em" }}>
          3. クルーを作成
        </p>
        <p style={{ marginBottom: "12px" }}>
          作成したクルーはコピー&amp;ペーストで友達に簡単に共有することができます。<br />
          クルーを用いたおすすめの遊び方は以下に記載しています。
        </p>
      </div>


      <h1 style={{
        textAlign: 'center',
        fontSize: '1.5em',
        borderBottom: '2px solid #ddd',
        paddingBottom: '10px'
      }}>
        おすすめの遊び方
      </h1>
      <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>1on1クルー</p>
      <p style={{ fontSize: "14px", fontWeight: "bold", margin: "10px" }}>2人用</p>
      <p style={{ display: "block", fontSize: "14px", margin: "12px" }}>
        <p style={{ margin: "0 0 12px 0" }}>
          クルーをファイター5体・3ストックで設定し（お好みで問題ありません）、お互いにクルーを共有します。ファイターを選択して対戦します。

          対戦完了後に消費したストックを反映させます。
        
        残ストックが2 ストック以下のファイターを選択した場合、対戦開始直後に<strong>自滅</strong>して調整してください。<br />
        相手ファイターの残ストックを全て0にすれば勝利です！</p>
      </p>


      <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>擬似おま5</p>
      <p style={{ fontSize: "14px", fontWeight: "bold", margin: "10px" }}>2人用</p>
      <p style={{ display: "block", fontSize: "14px", margin: "12px" }}>
        <p style={{ margin: "0 0 12px 0" }}>
          クルーをファイター5体・3ストックで設定し、ファイター選択は全ておまかせで、作成し共有します。
          作成されたクルーのNo通りの順番で対戦を進めてください。ストックの引き継ぎによって、おま5に近いゲーム性で遊ぶことができます。
        </p>
      </p>


      <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>1on1チームクルー</p>
      <p style={{ fontSize: "14px", fontWeight: "bold", margin: "10px" }}>4人用</p>
      <p style={{ display: "block", fontSize: "14px", margin: "12px" }}>
        <p style={{ margin: "0 0 12px 0" }}>
          2チームに分かれて、各チームでクルーを作成します。毎試合、各チーム1人が代表してファイターを選択して<strong>1on1対戦</strong>をします。同じ人同士での対戦にならないように、総当たりの組み合わせで回す等、工夫してください。
        </p>
      </p>

      <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>2on2チームクルー</p>
      <p style={{ fontSize: "14px", fontWeight: "bold", margin: "10px" }}>4人用</p>
      <p style={{ display: "block", fontSize: "14px", margin: "12px" }}>
        <p style={{ margin: "0 0 12px 0" }}>
          2チームに分かれて、各チームでクルーを作成します。全員がファイターを選択して<strong>2on2チーム戦</strong>をします。もし選択したファイターがチーム内で被り、<strong>残ストックを超過してしまった場合</strong>は、試合開始直後に残ストックに収まるように自滅してください。
        </p>
      </p>

    </div>


  )
}