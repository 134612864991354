import html2canvas from "html2canvas";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';

const CopyButton = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '50%',
  // transition: 'transform 0.05s',
  cursor: 'pointer',
  '&:active': {
    transform: 'scale(0.9)',
  },
});

const CustomCopyButton = ({ handleCopy }) => {
  return (
    <CopyButton onClick={handleCopy}>
      <ContentCopyIcon style={{ fontSize: '24px' }} />
    </CopyButton>
  );
};

export const CopyImgButton = ({ type }) => {
  let screenshotTitle = ""

  // ファイル名
  switch (type) {
    case "tier-table":
      screenshotTitle = "ファイターランク";
      break;
    case "omakase-tier-table":
      screenshotTitle = "おまかせランク";
      break;
    case "omakase-five-result":
      screenshotTitle = "おま５ピック結果";
      break;
    case "omakase-result":
      screenshotTitle = "おまかせピック結果";
      break;
    case "omakase-crew":
      screenshotTitle = "おまかせクルー";
      break;
    default:
      screenshotTitle = "対戦したいファイター";
      break;
  }

  const onClickExport = () => {
    const target = document.getElementById(type);
    const clone = target.cloneNode(true);

    const parent = document.createElement('div');

    const img = document.createElement('img');
    img.src = "/images/ロゴ.png";
    img.alt = "ロゴ";
    img.style.width = "200px";
    img.style.objectFit = "contain";
    img.style.margin = "auto 10px"

    parent.appendChild(img)

    // <p>要素を作成
    const title = document.createElement('p');
    title.textContent = screenshotTitle;
    title.style.display = "block";
    title.style.fontSize = "18px";
    title.style.fontWeight = "bold";

    // <hr>要素を作成
    const separator = document.createElement('hr');
    separator.style.border = "none";
    separator.style.height = "2px";
    separator.style.background = "black";
    separator.style.margin = "0px";

    // parentに<p>と<hr>を追加
    parent.appendChild(title);
    parent.appendChild(separator);

    parent.appendChild(clone)
    parent.style.padding = '20px'
    parent.style.width = `600px`;
    parent.style.position = 'absolute';
    parent.style.top = '-9999px'; // 画面外に配置

    document.body.appendChild(parent);

    html2canvas(parent, {
      useCORS: true, // 外部リソースのクロスオリジン問題を回避するため
    }).then(canvas => {
      // canvas を Blob に変換
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Blob変換に失敗しました");
          return;
        }
        // ClipboardItem を作成
        const item = new ClipboardItem({ "image/png": blob });

        // クリップボードに書き込む
        navigator.clipboard.write([item]).then(() => {
        }).catch(err => {
        });
      }, "image/png");
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", fontWeight: "bold", fontSize: "10px" }}>
      コピー
      <CustomCopyButton handleCopy={onClickExport} />
    </div>
  )
}